body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.card {
  border-radius: 4px;
  color: darkslategray;
  margin: 15px;
  padding: 15px;
  height: 85vh;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
}
.card-tale {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.content {
  height: 80%;
  text-align: left;
  padding: 0 15px 0 25px;
}
.actions {
  height: 20%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

.actions div {
  width: 38%;
  height: 60%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  cursor: pointer;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

.yeap {
  color: #373738;
  border-radius: 4px;
  padding: 10px;
  box-shadow: inset 0px 0px 10px -3px rgba(147,224,147,1);
}
.nope {
  color: #373738;
  border-radius: 4px;
  padding: 10px;
  box-shadow: inset 0px 0px 10px -3px rgba(255,114,124,1);
}

.login img {
  width: 30%;
  border-radius: 100%;
  border: 2px solid white;
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
}

.upper {
  line-height: 45px;
}

.progress {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  padding: 0 15px 0 25px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-right: ;
}

.progress-bar {
  border-radius: 10px;
  width: 100%;
  height: 10px;
  margin-right: 15px;
  box-shadow: 0px 0px 3px -1px rgba(0,0,0,0.75);
}
.progress-bar>div {
  height: 10px;
  background: #caf2ff;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

